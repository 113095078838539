<template>
  <v-navigation-drawer
    fixed
    v-model="drawer"
    right
    width="400px"
    height="max-content"
    temporary
    style="z-index: 100; min-height: 100svh"
  >
    <div
      style="
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 20px;
      "
      :key="schemeKey"
    >
      <div
        v-for="(row, rowIndex) in new_seats_scheme"
        :key="rowIndex"
        style="display: flex; flex-direction: column; margin-right: 10px"
      >
        <div
          v-for="(seat, seatIndex) in row"
          :key="seatIndex"
          class="seat"
          style="cursor: pointer; user-select: none; position: relative"
          @click="
            seat.for_sell ? chooseSeat(seat.number, rowIndex, seatIndex) : ''
          "
        >
          <span
            v-if="seat.type === 'seats'"
            style="
              position: absolute;
              color: white;
              z-index: 100;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          >
            {{ seat?.number }}
          </span>
          <img
            width="42px"
            height="42px"
            v-if="seat.type !== 'aisle' && seat.type !== 'seats'"
            :src="getSeatImage(seat?.type)"
            :alt="seat.type"
            style="transform: rotate(-90deg)"
          />
          <div
            v-else-if="seat.type === 'seats'"
            class="seatsIcon"
            :style="
              seat.status === 'Sold'
                ? 'background: #6B7C8A; pointer-events: none;'
                : seat.status === 'Reserved' || seat.status === 'Booked'
                ? 'background: #FBBB55'
                : seat.for_sell
                ? 'background: #149E51'
                : 'background: #6B7C8A; pointer-events: none;'
            "
            style="width: 42px; height: 42px; transform: rotate(-90deg)"
          ></div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
  
  <script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    drawer: true,
    new_seats_scheme: [],
    schemeKey: 0,
  }),
  props: {
    seats_scheme: {
      require: true,
    },
    passanger: {
      require: true,
    },
  },
  mounted() {
    this.new_seats_scheme = this.seats_scheme;
  },
  methods: {
    getSeatImage(type) {
      switch (type) {
        case "seats":
          return require("@/assets/img/busConstructor/seat.svg");
        case "service":
          return require("@/assets/img/busConstructor/service.svg");
        case "door":
          return require("@/assets/img/busConstructor/door.svg");
        case "handelbar":
          return require("@/assets/img/busConstructor/handelbar.svg");
        default:
          return "";
      }
    },
    async chooseSeat(number) {
      this.$emit("chooseSeat", number, this.passanger.id);
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
  watch: {
    drawer: {
      handler() {
        if (!this.drawer) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
  
  <style>
.quantitySeatsBlock {
  margin-right: 12px;
}
.quantitySeatsBlock:nth-child(7n) {
  margin-right: 0px;
}
.seatsBlock {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  user-select: none;
}
.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #243949;
  margin-left: 10px;
}
.seat {
  border: 1px solid silver;
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
}
.seatsIcon {
  width: 42px;
  height: 42px;
  background-color: #149e51;
  -webkit-mask: url("../../../../assets/img/busConstructor/seat.svg") no-repeat
    center;
  mask: url("../../../../assets/img/busConstructor/seat.svg") no-repeat center;
}

.iconBackground:hover .dragIcon {
  background-color: #fafafa;
}
</style>