<template>
  <div class="passangerInfoBackground">
    <v-row no-gutters align="center">
      <span class="passanger"
        >{{ "passanger_label" | localize }} №{{ index }}</span
      >
      <v-row no-gutters justify="end">
        <!-- <span class="priceInfo">{{ passanger.price }} грн</span> -->
        <div
          v-if="index > 1"
          class="deleteIcon"
          style="cursor: pointer"
          @click="$emit('deletePassanger', passanger.id)"
        />
      </v-row>
    </v-row>
    <div class="sectionLine" style="margin: 20px 0px" />
    <v-row no-gutters align="center">
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'first_name_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passanger.first_name"
          :error-messages="firstNameError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'last_name_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passanger.last_name"
          :error-messages="lastNameError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'phone_number_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-mask="'+###############'"
          v-model="passanger.phone_number"
          :error-messages="phoneNumberError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          label="Email"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passanger.email"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-autocomplete
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'passanger_type_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          hide-details
          v-model="passanger.type"
          :items="Object.values(passangerTypes)"
          :item-text="(item) => item?.translations?.title"
          :item-value="'id'"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          label="Ціна"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          hide-details
          v-model="passanger.price"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        v-if="seats_type == 'Reserved'"
      >
        <div
          style="
            height: 48px;
            border: 2px solid #eeeeee;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border: 2px solid #eeeeee;
          "
          :style="
            seatNumberError.length
              ? 'border-color:#FF5252'
              : 'border-color:#eeeeee'
          "
          @click="showSeatsDrawer = true"
        >
          <v-row no-gutters align="center">
            <h4 v-if="passanger.seat_number">
              {{ passanger.seat_number }}
            </h4>
            <h4 v-else>Виберіть місце</h4>
            <v-row no-gutters justify="end" align="center">
              <v-icon size="30px">mdi-chevron-right</v-icon>
            </v-row>
          </v-row>
        </div>
        <span v-if="seatNumberError" style="color: #ff5252; font-size: 12px">
          {{ seatNumberError?.[0] }}
        </span>
      </v-col>
    </v-row>
    <admin-seats-drawer
      v-if="showSeatsDrawer"
      :seats_scheme="new_seats_scheme"
      :passanger="passangerInfo"
      @chooseSeat="chooseSeat"
      @close="showSeatsDrawer = false"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import AdminSeatsDrawer from "./adminSeatsDrawer.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AdminSeatsDrawer },
  mixins: [validationMixin],
  data: () => ({
    passanger: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      type: "",
    },
    showSeatsDrawer: false,
    new_seats_scheme: null,
  }),
  props: {
    index: {
      require: true,
    },
    touch: {
      require: false,
    },
    passangerTypes: {
      require: true,
    },
    passangerInfo: { require: true },
    seats_type: {
      require: true,
    },
    seats_scheme: {
      require: true,
    },
  },
  validations: {
    passanger: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
      seat_number: {
        required,
      },
      // type: {
      //   required,
      // },
    },
  },
  mounted() {
    this.new_seats_scheme = this.seats_scheme;
    this.passanger = this.passangerInfo;
    this.checkPrice();
  },
  methods: {
    ...mapActions(["updateSchedule"]),
    // setPrice() {
    //   let initialValue = 0;
    //   let discountSum = this.passanger.discounts.reduce(
    //     (accumulator, item) =>
    //       accumulator + (item.price_before - item.price_after),
    //     initialValue
    //   );
    //   this.passanger.price =
    //     this.passanger.full_price -
    //       discountSum -
    //       (this.passanger.full_price - discountSum) *
    //         (this.passangerTypes.find(
    //           (type) => type.id == this.passanger.type
    //         )?.amount /
    //           100) || this.passanger.full_price - discountSum;
    // },
    checkPrice() {
      let passanger = this.passangerInfo;
      if (passanger.type !== "") {
        let discount = this.passangerTypes.find(
          (discount) => discount.id == passanger.type
        );
        if (discount.amount_type == "Percent") {
          passanger.price =
            passanger.full_price -
            passanger.full_price * (discount.amount / 100);
        } else {
          passanger.price = passanger.full_price - discount.amount;
        }
      } else {
        passanger.price = passanger.full_price;
      }
      this.passanger = passanger;
    },
    chooseSeat(seat) {
      // Get old seat
      const passangerSeat = this.passanger.seat_number;
      // Update new passanger seat
      this.passanger.seat_number = seat;

      // Update status old seat if seat a choosed
      const oldSeat = this.seats_scheme
        .flat()
        .find((scheme) => scheme.number === passangerSeat);
      if (oldSeat) {
        oldSeat.status = "Available";
      }

      // Update status new seat
      const newSeat = this.seats_scheme
        .flat()
        .find((scheme) => scheme.number === seat);
      if (newSeat) {
        newSeat.status = "Booked";
      }

      //Update seat scheme
      this.updateSchedule({
        ...this.schedule,
        seats_scheme: this.seats_scheme,
      });

      // Close choose seat drawer
      this.$v.passanger.seat_number.$reset();
      this.showSeatsDrawer = false;
    },
  },
  computed: {
    ...mapGetters(["schedule"]),
    firstNameError() {
      const errors = [];
      let field = this.$v.passanger.first_name;
      if (!field.$dirty) {
        return errors;
      }
      if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      let field = this.$v.passanger.last_name;
      if (!field.$dirty) {
        return errors;
      }
      if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      let field = this.$v.passanger.phone_number;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.minLength) {
        errors.push("");
      }
      return errors;
    },
    seatNumberError() {
      const errors = [];
      let field = this.$v.passanger.seat_number;
      if (!field.$dirty) {
        return errors;
      }
      if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    // typeError() {
    //   const errors = [];
    //   let field = this.$v.passanger.type;
    //   if (!field.$dirty) {
    //     return errors;
    //   }
    //   if (!field.required) {
    //     errors.push("");
    //   }
    //   return errors;
    // },
  },
  watch: {
    touch: {
      handler() {
        if (this.touch) {
          this.$v.$touch();
          console.log(this.$v);
          if (this.$v.$invalid) {
            console.log("WORK INVALID");
            this.$emit("invalidData");
          } else {
            console.log("WORK VALID");
            this.$emit("validData");
          }
        }
      },
    },
    "passanger.type": {
      handler() {
        this.checkPrice();
      },
    },
  },
};
</script>

<style scoped>
.passangerInfoBackground {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.passanger {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.priceInfo {
  color: #4b5262;
  font-size: 16px;
  font-weight: 600;
}
.deleteIcon:hover {
  background-color: #df3a3a;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>