<template>
  <v-col class="px-0 py-0">
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <div class="bookingTicketCardBackground" style="margin-top: 20px">
      <v-row no-gutters align="center">
        <span class="bookingTicketTitle"
          >{{ "journey_to_label" | localize }}
          <span style="color: #144fa9">{{
            schedule?.arrival?.translations?.name
          }}</span></span
        >
        <v-row no-gutters justify="end">
          <div class="changeTripBox" @click="changeSchedule">Змінити рейс</div>
        </v-row>
      </v-row>

      <div class="sectionLine" style="margin: 20px 0px" />
      <v-row no-gutters align="center">
        <div class="box" style="margin-right: 8px">
          {{ "departure_label" | localize }}
        </div>
        <span style="font-size: 14px; color: #4b5262; font-weight: 600">{{
          new Date(schedule.departed_at).toLocaleDateString("uk-UA", {
            day: "2-digit",
            month: "short",
          })
        }}</span>
      </v-row>
      <admin-booking-ticket-route-detail
        :detailOrder="schedule"
        style="margin-top: 12px"
      />
      <v-row no-gutters align="center" style="margin-top: 12px">
        <div class="box" style="background: #172b69; margin-right: 8px">
          {{ "arrival_label" | localize }}
        </div>
        <v-row no-gutters align="center">
          <span style="font-size: 14px; color: #4b5262; font-weight: 600"
            >{{
              new Date(schedule?.arrival_at).toLocaleDateString("uk-UA", {
                day: "2-digit",
                month: "short",
              })
            }}.</span
          >
          <div
            style="
              width: 4px;
              height: 4px;
              background: #4b5262;
              border-radius: 50%;
              margin: 0px 8px;
            "
          ></div>
          <span style="font-size: 14px; color: #4b5262; font-weight: 600"
            >{{ "duration_label" | localize }}
            {{ setDifferentTime(schedule?.departed_at, schedule?.arrival_at) }}
          </span>
        </v-row>
      </v-row>
    </div>
    <admin-passanger-info-form
      style="margin-top: 20px"
      v-for="(passanger, index) in passangers"
      :index="index + 1"
      :passangerInfo="passanger"
      :key="index"
      :passangerTypes="passangerTypes"
      :touch="touch"
      :seats_type="schedule.reservation_type"
      :seats_scheme="schedule.seats_scheme"
      @deletePassanger="deletePassanger"
    />
    <div
      style="
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 8px;
        width: 100%;
        margin-top: 20px;
        cursor: pointer;
      "
      @click="setNewPassanger"
    >
      <v-row
        no-gutters
        align="center"
        justify="center"
        style="pointer-events: none"
      >
        <add-new-item-btn text="Додати пасажира" />
      </v-row>
    </div>
    <div class="bookingTicketCardBackground" style="margin-top: 20px">
      <span class="bookingTicketTitle">Примітка</span>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-textarea
        outlined
        dense
        v-model="comment"
        hide-details
        color="#144FA9"
        placeholder="Введіть тут.."
        style="border-radius: 10px"
      />
    </div>
    <!-- PRICE INFO CARD -->
    <div class="bookingTicketCardBackground" style="margin-top: 20px">
      <span class="bookingTicketTitle">{{
        "price_information_label" | localize
      }}</span>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-row no-gutters align="end">
        <v-col cols="6" style="padding-right: 10px">
          <v-row no-gutters align="center" style="margin-bottom: 20px">
            <div class="userIcon" style="margin-right: 8px" />
            <span style="color: #4b5262; font-size: 16px; font-weight: 600"
              >{{ $route.query?.qty }}
              {{ getPassengerText($route.query?.qty) }}</span
            >
          </v-row>
          <v-row
            no-gutters
            justify="space-between"
            v-for="(passanger_detail, key, index) in passangerTypesForUser"
            :key="(passanger_detail.id, index)"
          >
            <v-col class="px-0 py-0">
              <v-row
                no-gutters
                align="center"
                justify="space-between"
                v-for="item in passanger_detail"
                :key="item.id"
                :style="'margin-top: 12px;'"
              >
                <span style="font-size: 14px; font-weight: 400"
                  >{{ item.length }} {{ item?.[0]?.title }}</span
                >
                <span style="font-size: 14px; color: #4b5262; font-weight: 600"
                  >{{ parseFloat(key) * item.length }} грн</span
                >
              </v-row>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            justify="space-between"
            :style="'margin-top: 12px;'"
            v-if="discountAmount > 0"
          >
            <span style="font-size: 14px; font-weight: 400">Знижка</span>
            <span style="font-size: 14px; color: #4b5262; font-weight: 600"
              >-{{ discountAmount.toFixed(2) }} грн</span
            >
          </v-row>
        </v-col>
        <v-col cols="6" style="padding-left: 10px">
          <div style="border-radius: 10px; background: #e7edf6; padding: 16px">
            <v-row no-gutters align="center">
              <span
                style="
                  color: #1b1b1b;
                  font-family: 'MacPaw Fixel';
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                "
                >Вартість поїздки</span
              >
              <v-row no-gutters align="center" justify="end">
                <span
                  style="
                    color: #144fa9;
                    font-family: 'MacPaw Fixel';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  "
                  >{{ orderSum }} грн</span
                >
              </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters justify="end" align="center" style="margin-top: 40px">
      <cancel-btn text="Скасувати" @click="$router.back()" />
      <submit-button
        @click="createOrder('booking')"
        style="width: 240px !important; font-weight: 700; margin-left: 10px"
        >Оплата при посадці</submit-button
      >
      <submit-button
        @click="createOrder('payed')"
        style="width: 240px !important; font-weight: 700; margin-left: 10px"
        >Оплачено фізично</submit-button
      >
      <submit-button
        @click="createOrder('pay')"
        style="width: 240px !important; font-weight: 700; margin-left: 10px"
        >Оплатити</submit-button
      >
    </v-row>
    <booking-ticket-payment-modal
      v-if="showPaymentModal"
      :visible="showPaymentModal"
      :totalPrice="final_price"
      :liqPayBtn="liqpay_btn"
      :passangerTypesForUser="passangerTypesForUser"
      :detailOrder="schedule"
      @close="showPaymentModal = false"
    />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import diffrentTimeMixin from "../../../../mixins/diffrentTimeMixin";
import socialService from "../../../../requests/Admin/Discount/socialService";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import BookingTicketPaymentModal from "../../../User/bookingTicket/bookingTicketPaymentModal.vue";
import AdminBookingTicketRouteDetail from "../Booking/adminBookingTicketRouteDetail.vue";
import AdminPassangerInfoForm from "../Booking/adminPassangerInfoForm.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import AddNewItemBtn from "../../../UI/Buttons/addNewItemBtn.vue";
import requestFormData from "../../../../requests/requestFormData";
import adminSearchScheduleService from "@/requests/Admin/adminSearchScheduleService.js";
import bookingTicketService from "../../../../requests/User/bookingTicketService";
import ErrorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
export default {
  mixins: [diffrentTimeMixin, validationMixin],
  components: {
    AdminBookingTicketRouteDetail,
    CancelBtn,
    SubmitButton,
    AdminPassangerInfoForm,
    BookingTicketPaymentModal,
    AddNewItemBtn,
    ErrorSnackbar,
  },
  data: () => ({
    passangerTypes: [],
    passangerTypesForUser: [],
    passangers: [],
    orderSum: 0,
    discountAmount: 0,
    final_price: 0,
    bonus_amount: parseFloat(localStorage.getItem("bonus_amount")) / 100,
    showPaymentModal: false,
    touch: false,
    comment: "",
    liqpay_btn: "",
    snackbarText: "",
    showErrorSnackbar: false,
  }),
  validations: {
    passangers: {
      $each: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        phone_number: {
          required,
          minLength: minLength(11),
        },
        email: {
          required,
          email,
        },
      },
    },
  },
  mounted() {
    this.getUserTypes();
  },
  methods: {
    async getUserTypes() {
      await socialService.getSocials().then((res) => {
        console.log("RES", res);
        if (res.status == "Success") {
          this.passangerTypes = res.data.map(
            (type) =>
              (type = {
                ...type,
                translations: type.translations.find(
                  (translate) => translate.lang == "ua"
                ),
              })
          );
          this.passangerTypes.unshift({
            translations: {
              title: "Повний",
            },
            id: "",
          });
        }
        this.setQuantityPassangers();
      });
    },
    async createOrder(type) {
      this.touch = true;
      if (!this.$v.$invalid) {
        let recent_search = JSON.parse(
          sessionStorage.getItem("recent_trip_search_admin")
        );
        let tickets = [];
        this.passangers.forEach((passanger) => {
          tickets.push({
            first_name: passanger.first_name,
            last_name: passanger.last_name,
            email: passanger.email,
            phone_number: passanger.phone_number.replace(/\D+/g, ""),
            seat_number: passanger.seat_number,
          });
        });
        if (type == "booking") {
          this.comment = `${this.comment} \nОплата при посадці`;
        }
        let data = {
          departure: recent_search.departureCity,
          destination: recent_search.arrivalCity,
          ticket_count: this.$route?.query?.qty,
          schedule_uuid: this.schedule.id,
          comment: this.comment,
          manager_discount: this.discountAmount > 0 ? this.discountAmount : "",
          ticket: tickets,
        };
        if (type == "payed") {
          data.is_payed = "Yes";
          data.status = "Payed";
        }
        if (type == "booking") {
          data.is_payed = "No";
          data.status = "PayInBus";
        }
        if (data.manager_discount == "") {
          delete data.manager_discount;
        }
        let form = requestFormData.jsonToFormData(data);
        await adminSearchScheduleService
          .createOrder(form)
          .then(async (res) => {
            if (res.status == "Success") {
              if (type == "pay") {
                this.getPaymentModal(res.data.id);
              } else {
                this.$router.push("/admin/trips");
              }
            }
          })
          .catch((e) => {
            if (e.response.data.message == "Seat is not available") {
              this.snackbarText = "Деякі місця вже заброньовані, виберіть інші";
              this.showErrorSnackbar = true;
              setTimeout(() => {
                this.showErrorSnackbar = false;
              }, 3000);
            }
            console.log(
              "E",
              e,
              e.response.data.message,
              e.response.data.message == "Seat is not available"
            );
          });
      } else {
        setTimeout(() => {
          this.touch = false;
        }, 500);
      }
    },
    setQuantityPassangers() {
      for (let index = 0; index < this.$route?.query?.qty; index++) {
        this.passangers.push({
          id: index,
          first_name: "",
          last_name: "",
          phone_number: "",
          email: "",
          type: "",
          price: this.schedule?.price?.UAH?.price,
          full_price: this.schedule?.price?.UAH?.price,
        });
      }
    },
    setNewPassanger() {
      let qty = parseInt(this.$route?.query?.qty);
      qty++;
      this.$router.push(`/admin/booking?qty=${qty}`);
      this.passangers.push({
        id: this.passangers.length,
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        type: "",
        price: this.schedule?.price?.UAH?.price,
        full_price: this.schedule?.price?.UAH?.price,
      });
    },
    deletePassanger(id) {
      this.passangers = this.passangers.filter(
        (passanger) => passanger.id !== id
      );
      this.$router.push(`/admin/booking?qty=${this.passangers.length}`);
    },
    getPassengerText(count) {
      if (count === 1) {
        return this.$options.filters.localize("passanger_label").toLowerCase();
      } else if (count > 1 && count < 5) {
        return this.$options.filters.localize("passangers_label").toLowerCase();
      } else {
        return this.$options.filters
          .localize("to_many_passanger_label")
          .toLowerCase();
      }
    },
    groupByPrice() {
      const groupedItems = {};
      this.discountAmount = 0;
      this.passangers.forEach((item) => {
        const price = item.price;
        item.price < item.full_price
          ? (this.discountAmount += item.full_price - item.price)
          : "";
        const title = this.passangerTypes.find(
          (passanger) => passanger.id == item.type
        )?.translations?.title;
        // Перевіряємо, чи ключ з цією ціною вже існує
        if (!groupedItems[price]) {
          groupedItems[price] = {};
        }

        // Перевіряємо, чи ключ з цим заголовком вже існує
        if (!groupedItems[price][title]) {
          groupedItems[price][title] = [];
        }

        // Додаємо поточний об'єкт до масиву об'єктів з такою ж ціною та заголовком
        groupedItems[price][title].push({ ...item, title: title });
      });
      this.passangerTypesForUser = groupedItems;
    },
    async getPaymentModal(order_id) {
      await bookingTicketService.getPayBtns(order_id).then((res) => {
        if (res.status == "Success") {
          this.liqpay_btn = res.data.liqpay.replace(
            'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
            'src="https://i.ibb.co/SP1cZmj/Group-82.png"'
          );
          this.final_price = res.data?.order?.final_price;
          setTimeout(() => {
            this.showPaymentModal = true;
          }, 100);
        }
      });
    },
    changeSchedule() {
      let recent_search = JSON.parse(
        sessionStorage.getItem("recent_trip_search_admin")
      );
      this.$router.push(
        `/admin/trips?departureCity=${recent_search.departureCity}&arrivalCity=${recent_search.arrivalCity}&startDate=${recent_search.start_date}&endDate=${recent_search.end_date}&qty=${this.$route?.query?.qty}`
      );
    },
  },
  computed: {
    ...mapGetters(["schedule"]),
  },
  watch: {
    passangers: {
      deep: true,
      handler() {
        this.orderSum = 0;
        this.orderSum = this.passangers.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.price),
          this.orderSum
        );
        this.groupByPrice();
      },
    },
  },
};
</script>

<style scoped>
.bookingTicketText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 400;
}
.bookingTicketTitle {
  font-size: 20px;
  font-weight: 700;
}
.bookingTicketCardBackground {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.socialBtn {
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 12px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
  width: 100%;
}
.box {
  border-radius: 10px;
  background: #144fa9;
  width: max-content;
  padding: 4px 8px;
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.changeTripBox {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  font-size: 12px;
  font-weight: 400;
  color: #1b1b1b;
  padding: 4px 8px;
  user-select: none;
  cursor: pointer;
}
.changeTripBox:hover {
  background: #144fa9;
  color: #fff;
}
</style>