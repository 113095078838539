import requestService from "../../requestService";
import store from '@/store';

export default {
    async createSocial(form) {
        const response = await requestService.post(`/cp_dash/social`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateSocial(id, form) {
        const response = await requestService.post(`/cp_dash/social/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async changeSocialStatus(id, form) {
        const response = await requestService.post(`/cp_dash/social/status/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getSocials(status,page) {
        const response = await requestService.get(`/cp_dash/social${status !== 'All' && status !== null && status !== undefined && status !== '' ? `/status/${status}` : ''}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getSocialsByStatus(status,page) {
        const response = await requestService.get(`/cp_dash/social/status/${status}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getSocial(id) {
        const response = await requestService.get(`/cp_dash/social/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getSocialsByUser() {
        const response = await requestService.get(`/v1/social`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
}